@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

@font-face {
	font-family: PoppinsBolder;
	src: url('assets/fonts/Poppins-Regular.ttf');
}

html {
	scroll-behavior: smooth;
	font-family: 'Poppins', sans-serif !important;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
	overflow-x: hidden;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

strong {
	font-family: PoppinsBolder;
}