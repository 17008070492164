.listItem {
    cursor: pointer;
    transition: .25s;
    text-decoration: none;
    font-weight: bolder;
    font-size: 15px;
}

.listItem:hover {
    color: orange;
}
