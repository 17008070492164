.container {
    border-top: .4em solid #fff;
    transition: .4s !important;
}

.container:hover {
    border-top-color: #FF6A17;
}

.cardContent {
    height: 100%;
    padding-bottom: 3em !important;
}
