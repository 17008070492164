.container {
    color: white;
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.video {
    min-height: 150%;
    position: absolute;
    z-index: -1;
    transform: translateX(-25%);
}